@import "../../variables.scss";

.SingleSelectDropdown {
    margin-bottom: .5rem;

    tbody {
        tr {
            td {
                background-color: #fff;
            }
        }
    }

    thead {
        tr {
            td {
                background: #ECECEC;
            }
        }
    }


    tr {
        td {
            border-top: none;
            font-weight: bold;
        }
    }
}