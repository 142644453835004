$PMDE-white: #fff;
$PMDE-gray-lighter: #F7F7F7;
$PMDE-gray-light: #EFEFEF;
$PMDE-gray-medium: #BABABA;

$PMDE-divider-style: 1px dashed $PMDE-gray-medium;

$PMDE-primary: #005ea2;
$PMDE-primary-dark: #162E51;

$PMDE-black: #1b1b1b;
$PMDE-error: rgb(218, 59, 23);

$ipad-size: 640px;