.BarChart {
    height: 100%;

    .buttonGroup {
        margin: 0 .5rem !important;
        top: 0;
        position: sticky;
        right: 0;
    }

    .chartContainer {
        margin-right: .5rem;
        margin-left: .5rem;
        background-color: white;
        display: flex;
        flex-direction: column;

        svg {
            background-color: white;
            order: 3;
          }
      
          .chartCaption, .chartTitle, .countCaption {
              display: none;
          }
      
          .chartCaption, .countCaption {
              font-size: 12px;
              font-style: italic;
          }
      
          .chartCaption {
            display: none;
            order: 4;
          }
      
          .countCaption {
            order: 2;
          }
      
          .chartTitle {
            order: 1;
            font-size: 16px;
            font-weight: bold;
          }
      
    }

}


@media screen and (max-width: 1000) {
    .BarChart {
        width: 100vh;
    }
}