@import "../../variables.scss";

.MainContent {
    padding: 20px 20px 20px 10px;
    height: 100%;

    .errorWrapper {
        margin: 0.5rem;
    }

    @media screen and (max-width: $ipad-size) {
        height: auto;
        overflow: visible;
    }

    .resultsTitle {
        margin-right: .5rem;
        margin-left: .5rem;
    }

    .legendRect {
        height: 15px;
        background: rgb(203, 229, 240);
        background: linear-gradient(90deg, rgba(203, 229, 240, 1) 0%, rgba(43, 143, 181, 1) 50%, rgba(1, 15, 21, 1) 100%);
        flex: 1 0 150px;
        margin: 0px .5rem;
    }

    .legendLabel {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
    }


    .MainContentOverflow {
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        height: 100%;
        min-width: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        @media screen and (max-width: $ipad-size) {
            flex-direction: column;
            overflow-y: unset;
            overflow-x: unset;
        }

        .results {
            width: 85%;
            display: flex;
            flex-flow: column;

            @media screen and (min-width: $ipad-size) {
                min-width: 800px;
            }

            @media screen and (max-width: $ipad-size) {
                width: 100%;
            }
        }

        .details {
            min-width: 15%;
            max-width: 200px;
            overflow-x: visible;

            @media screen and (min-width: $ipad-size) {
                // min-width: 275px;
            }

            @media screen and (max-width: $ipad-size) {
                width: 100%;
                min-width: 100%;
                max-width: 100%;
            }

            // max-width: 400px;
        }
    }

    hr {
        width: 100%;
        border: .5px solid black;
        margin: 0px;
    }

    p {
        margin: .5rem 0px;
    }

    .filtersLabel {
        font-family: 'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        font-size: 12px;
        line-height: 14px;

    }

    .filtersContainer {
        margin: 5px 0px 10px;
    }

    .details {
        height: 100%;


        button {
            margin: .5rem 0;
        }
    }

    .filters {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 100px;

        @media screen and (max-width: $ipad-size) {
            height: auto;
            overflow-y: visible;
            padding-bottom: 0px;
        }
    }

    .displayDropdownsWrapper {

        .dropdown {
            margin: .5rem;
        }


        @media screen and (max-width: $ipad-size) {
            flex-direction: column;

            .dropdown {
                flex-grow: 0;
            }
        }
    }
}