@import "../../variables.scss";

.Help {
    .HelpTourIMG {
        width: 100%;
        margin: 0rem 0 1rem;
    }

    a {
        display: flex;
        align-items: center;
        color: $PMDE-white;
        font-weight: bold;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }


        svg {
            margin-left: .5rem;
        }
    }
}