@import "./variables.scss";

@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("/assets/fonts/public-sans/PublicSans-Bold.woff2") format("woff2"),
       url("/assets/fonts/public-sans/PublicSans-Bold.woff") format("woff"),
       url("/assets/fonts/public-sans/PublicSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("/assets/fonts/public-sans/PublicSans-Regular.woff2") format("woff2"),
       url("/assets/fonts/public-sans/PublicSans-Regular.woff") format("woff"),
       url("/assets/fonts/public-sans/PublicSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url("/assets/fonts/public-sans/PublicSans-Italic.woff2") format("woff2"),
       url("/assets/fonts/public-sans/PublicSans-Italic.woff") format("woff"),
       url("/assets/fonts/public-sans/PublicSans-Italic.ttf") format("truetype");
}

// uswds overrides

.App,
.usa-radio__label,
.usa-component,
.usa-legend,
.MuiDrawer-root,
p {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

@media screen and (max-width: $ipad-size) {
  .MuiDrawer-root {
    .MuiPaper-root {
      width: 100vw;
    }
  }
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

main {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;

  @media screen and (max-width: $ipad-size) {
    width: 100vw;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
  }
}

.tour {
  p {
    margin: 0.5rem 0;
  }

  .help-title {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;

    i {
      margin-right: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .sc-bZQynM {
    display: flex;
    justify-content: flex-end;

    .kcbIHb,
    .gnFBec {
      margin-right: 0.5rem;
      margin-left: 0px;
    }

    .fYzjNt {
      margin-left: 0px;
      margin-right: 0px;
    }

    .cYQqRL,
    .lfvGpo {
      font-size: 1.06rem;
      line-height: 0.9;
      color: #fff;
      background-color: $PMDE-primary;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 0;
      border-radius: 0.25rem;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      padding: 0.5rem 1.75rem;
      text-align: center;
      text-decoration: none;

      &:disabled {
        background-color: transparent;
        box-shadow: inset 0 0 0 2px #757575;
        color: #757575;
        cursor: auto;
      }

      &:not([disabled]):focus {
        outline-offset: 0.25rem;
      }

      .bWZsjK {
        font-size: 0.8rem;
        line-height: 1rem;
      }
    }

    .lfvGpo,
    .kcbIHb {
      background-color: transparent;
      box-shadow: inset 0 0 0 2px $PMDE-primary;
      color: $PMDE-primary;

      &:disabled {
        background-color: transparent;
        box-shadow: inset 0 0 0 2px #757575;
        color: #757575;
        cursor: auto;
      }
    }
  }
}

.tooltip {
  background-color: $PMDE-white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 12px;
  color: black;
  position: absolute;
  white-space: nowrap;
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.tooltip-html {
  display: flex !important;
  align-items: center !important;
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}

.tooltip-median {
  color: #2786aa !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.chartDesc {
  visibility: hidden;
  margin: 0;
  padding: 0;
  height: 0;
}

#tblDesc {
  visibility: hidden;
  margin: 0;
  padding: 0;
  height: 0;
}

.yTitle,
.xTitle {
  font-weight: bold;
}

.yTitle,
.xTitle,
.xlabel,
.ylabel,
.fakeYAxisLabel {
  @media screen and (min-width: $ipad-size) {
    font-size: 1rem;
  }

  @media screen and (max-width: $ipad-size) {
    font-size: 0.35rem;
  }
}

.usa-site-alert--emergency .usa-alert {
  background-color: #fcc34e;
  border-left-color: #fcc34e;
}

.usa-site-alert--emergency .usa-alert .usa-alert__body {
  background-color: #fcc34e;
  color: black;
  font-size: 14px;
}

.usa-site-alert--emergency .usa-alert .usa-alert__body::before {
  background: url("./assets/icons/alert-icon.png") no-repeat center/contain !important;
  background-color: transparent;
  background-size: contain;
  -webkit-mask: none !important;
}

.usa-alert__text {
  margin-left: 2em;
}

.download-button {
  padding: 0.5rem 0.75rem;
  margin-right: 0px;
  font-size: 1rem;
}

// inline style overrides
.filters-button {
  width: 100vw;
  display: flex;
  justify-content: center !important;
  align-items: center !important;

  i {
    margin-right: 0.5rem;
  }
  @media screen and (max-width: $ipad-size) {
    border-radius: 0px;
  }
}

.buttonFlex {
  display: flex;
}

.buttonNone {
  display: none;
}

.display-none {
  display: none !important;
}

.usa-button-teal {
  color: #009ca2 !important;
}

.linear-progress-container {
  height: 100% !important;
  width: 100% !important;
  background-color: #efefef !important;
}

.loading-container {
  width: 100% !important;
  height: 500px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.loading-container-inner {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

m-halfRem {
  margin: 0.5rem !important;
}
.mt-0 {
  margin-top: 0px !important;
}

.displayNone {
  display: none !important;
}

.displayBlock {
  display: block !important;
}

.mr-2 {
  margin-right: 2rem;
}

.clear-button {
  margin-top: 0.25rem;
}

.bar {
  transition: 0.25s;
  stroke: none;
}

.gtm-iframe {
  height: 0;
  width: 0;
  display: none;
  visibility: hidden;
}

.header,
.footer {
  background-color: $PMDE-gray-lighter;
}

.header {
  padding: 5px 10px;
}

.footer {
  padding: 5px;

  .footer-hyperlink {
    font-size: 0.8em;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.header {
  .headerButtonsInner {
    display: flex;
    align-items: center;

    button:not(:last-child) {
      margin: 0px 15px;
    }
  }
}

.boxGroup {
  cursor: pointer;
}

.Drawer {
  .closeButton {
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 300;
    margin: 1rem 0px;
  }

  h3 {
    text-transform: none;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.4em;
  }

  hr {
    border: 0.75px solid #fff;
  }

  p,
  a,
  .usa-radio__label {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

button,
a,
input,
select,
option,
.node {
  cursor: pointer !important;
}

.usa-legend {
  font-weight: bold;
  margin: 0.5rem 0rem 1rem;
  max-width: none;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.usa-radio__label {
  font-size: 1rem;
  line-height: 1.5rem;
}

.usa-component {
  font-size: 1rem;
  line-height: 1.62;
}

.usa-radio,
.usa-checkbox {
  background-color: inherit;
}

.box-and-whisker-sorting {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: row wrap;
}

.legend-container {
  justify-content: flex-end;

  h5 {
    margin: 0;
  }
}

.trend-chart-header {
  display: flex;
  justify-content: flex-end;
  margin: 0.5em 0;
}

.chart-header {
  justify-content: space-between;
}


.chart-footer {
  position: sticky;
  bottom: -1px;
  background: white; 
  padding: 10px;
  text-align: center;
  justify-content: flex-end;
}


.chart-header, .chart-footer {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin: 0.5em 0;

  .sorting-container {
    justify-content: flex-start;
    overflow: visible;

    h5 {
      margin: 0 0 0 5px;
    }
  }
}

.sorting-container,
.legend-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  top: 0;
  position: sticky;
  flex: 1;

  .sort-button-group {
    display: flex;
    justify-content: flex-end;
    margin: 0px;

    .usa-button {
      padding: 0.3em;
      font-size: 0.9em;
    }
  }
}

.viewed-as-button-group {
  .usa-button-group__item {
    flex: 1;

    .usa-button {
      width: 100% !important;
    }

    &.usa-button-group__item:last-child > .usa-button {
      margin-left: -4px;
    }

    &.usa-button-group__item:where(:not(:first-child):not(:last-child))
      > .usa-button {
      margin-left: -2px;
    }
  }
}

.usa-label,
.disabled-label {
  margin-top: 0px;
  font-weight: 700;
}

.disabled-label {
  color: gray;
}

.usa-table-container--scrollable {
  margin-top: 0px;
}

// for glossary button styling
.usa-label.grid-row,
.usa-legend.grid-row,
.usa-radio__label > .grid-row {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.usa-button-group--segmented
  .usa-button-group__item:where(:not(:last-child))
  .usa-button::before {
  border-right: 1px solid $PMDE-primary;
}

.usa-button.usa-button--active {
  background-color: $PMDE-primary;
  border-color: $PMDE-primary;
  box-shadow: inset 0 0 0 2px $PMDE-primary;

  svg {
    path {
      fill: $PMDE-white;
    }
  }
}

.usa-button--outline {
  border-color: $PMDE-primary;
  color: $PMDE-primary;
  box-shadow: inset 0 0 0 2px $PMDE-primary;

  svg {
    path {
      fill: $PMDE-primary;
    }
  }

  &.usa-button--active,
  &.usa-button--outline:active {
    border-color: $PMDE-primary;
    color: $PMDE-primary;
    box-shadow: inset 0 0 0 2px $PMDE-primary;
  }
}

// style overrides

.flex-grow {
  flex: 1 0 250px;
}

.max-width-775 {
  max-width: 775px;
}

.EUI-disclaimer {
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-style: italic;
  margin: 0.5rem !important;
}

.helper-text,
.error {
  font-size: 0.8rem;
  line-height: 1.2rem;
}

.error {
  color: $PMDE-error;
  background-color: mix(rgba(255, 255, 255, 1), $PMDE-error, 85%);
  padding: 1em;
  font-style: italic;
}

h2 {
  margin: 0px;
  font-size: 1.8rem;
  line-height: 2rem;
}

h3 {
  margin: 0px 0px 0.5rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: 1.5rem;
}

fieldset {
  border: none;
  padding: 0px;
  margin: 0px 0px 20px;

  legend {
    font-weight: 700;
  }
}

hr {
  border: $PMDE-divider-style;
  margin: 20px 0px;
}

.combo-box-hint {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0px;
  color: $PMDE-primary;
  cursor: pointer;
  font-size: 16px;

  &:disabled {
    color: #949a9f;
  }
}

.footnote {
  p {
    margin: 0px;
  }
}

.usa-tag {
  background-color: $PMDE-primary-dark;
  // white-space: nowrap;
  display: inline-block;
  margin-bottom: 0.25rem;
}

.usa-radio-white {
  .usa-radio__input:checked + [class*="__label"]::before {
    background: #fff;
    box-shadow: 0 0 0 2px #fff, inset 0 0 0 2px $PMDE-primary;
  }

  .usa-radio__label::before {
    background: transparent;
    box-shadow: 0 0 0 2px #fff;
  }

  label {
    color: #fff;
  }
}

.usa-button-white {
  background-color: #fff;
  color: $PMDE-primary;
  transition: background-color 0.5s;
}

// .euiDrawer {

.iconButton {
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 1em;
  width: 2em;
}

.range-slider__thumb, .range-slider__range {
  background: $PMDE-primary !important;
}

.margin-x-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.sortButton,
.resetButton,
.downloadImgButton {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.sortButton,
.downloadImgButton {
  height: 20px;

  @media screen and (max-width: $ipad-size) {
    height: 30px;
  }
}

.downloadImgButton {
  font-size: 0.9em;
  padding: 0.3em;
}

.resetButton {
  margin: 0.5em;
  justify-content: center;
}

.iconButton,
.iconButton:focus {
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 1em;
  width: 2em;
}

.externalLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: inherit;
}

.usa-select {
  background-image: url("../src/assets/icons/unfold_more.svg"),
    linear-gradient(transparent, transparent);
  background-size: 10px;
}

/* multiSelectDropdown is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */

.multiSelectDropdown {
  ::placeholder {
    color: $PMDE-black;
  }

  .node {
    margin: 0.5rem 0.2rem;

    .checkbox-item {
      margin-right: 0.5rem;
    }

    label {
      display: flex;
      align-items: center;

      //prevents checkboxes from squishing
      input {
        min-width: 1rem;
      }
    }
  }

  .node.tree {
    display: flex;
    align-items: center;

    &.partial {
      .checkbox-item {
        background-color: $PMDE-primary;
        box-shadow: 0 0 0 2px $PMDE-primary;

        &:before {
          content: url("../src/assets/icons/minus.svg");
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .node.leaf {
    display: flex;
    align-items: center;

    .toggle.collapsed {
      display: none;
    }
  }

  &.treeSelect {
    .node.leaf[aria-level="2"] {
      padding-left: 50px !important;
    }
    // only applies to "Not in a CBSA (rural)"
    .node.leaf[aria-level="1"] {
      padding-left: 22px !important;
    }
  }

  &.singleSelect {
    .node.leaf {
      padding-left: 5px !important;
    }
  }

  .toggle {
    font: normal normal normal 18px/1 "Material Icons";
    color: $PMDE-black;
    white-space: pre;
    margin-right: 4px;
    display: flex;
    align-items: center;

    &.collapsed::after,
    &.expanded::after {
      cursor: pointer;
      content: "\E5CF";
      vertical-align: middle;
      height: auto;
    }

    &.collapsed::after {
      transform: rotate(270deg);
    }
  }

  .dropdown-trigger > span:after {
    font-size: 12px;
    color: $PMDE-black;
  }

  /* checkbox styles */
  .checkbox-item {
    position: relative;
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    vertical-align: middle;
    border-radius: 2px;
    box-shadow: 0 0 0 1px #000;

    &:checked {
      background-color: $PMDE-primary;
      box-shadow: 0 0 0 2px $PMDE-primary;

      &:before {
        content: url("../src/assets/icons/check.svg");
        width: 100%;
        height: 100%;
      }
    }
  }

  a.dropdown-trigger {
    input {
      width: 100%;
      background-repeat: no-repeat;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #fff;
      background-position: right 0.5rem center;
      border-width: 1px;
      border-color: #565c65;
      border-style: solid;
      border-radius: 0;
      color: $PMDE-black;
      display: block;
      height: 2.5rem;
      margin-top: 0.5rem;
      padding: 0.5rem;
    }
  }

  .tag-list {
    width: 100%;
    margin: 0px;

    .tag-item {
      width: auto;

      .tag {
        display: none;
        font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto,
          Arial, sans-serif;
        font-size: 1.06rem;
        line-height: 1.3;
        background-color: $PMDE-primary-dark;
        text-transform: uppercase;
        color: #fff;
        border-radius: 2px;
        margin-right: 0.25rem;

        button {
          color: #fff;
          border: none;
          background-color: transparent;
        }
      }
    }
  }

  ul {
    list-style-type: none;
    margin-bottom: 0px;
    padding: 0;
  }

  .dropdown-content {
    background-color: white;
    max-height: 150px;
    overflow-y: scroll;
    z-index: 1000;
    width: 100%;
    margin: 0px;
    padding: 2px;
    border-color: #565c65;
    border-style: solid;
    border-radius: 0;
    color: $PMDE-black;
    border-width: 1px;
    border-top: none;

    ul {
      width: 100%;
      margin-top: 0px;

      .infinite-scroll-component {
        width: 100%;

        li {
          width: 100%;
        }
      }
    }

    .no-matches {
      width: 100%;
      display: block;
      padding: 0.5rem;
    }
  }
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (hover: hover) and (pointer: fine) {
  .tour .sc-bZQynM .cYQqRL,
  .tour .sc-bZQynM .lfvGpo {
    &:not([disabled]):hover {
      color: #fff;
      background-color: $PMDE-primary-dark;
      border-bottom: 0;
      text-decoration: none;
    }
  }

  .tour .sc-bZQynM .lfvGpo,
  .tour .sc-bZQynM .kcbIHb {
    &:not([disabled]):hover {
      background-color: transparent;
      box-shadow: inset 0 0 0 2px $PMDE-primary-dark;
      color: $PMDE-primary-dark;
    }
  }

  .bar {
    &:hover {
      -webkit-filter: brightness(70%);
      stroke: #000;
      stroke-width: 2px;
    }
  }

  .combo-box-hint {
    &:hover {
      text-decoration: underline;
    }
  }

  .usa-button--outline:hover {
    background-color: mix($PMDE-primary, transparent, 15%);
    box-shadow: inset 0 0 0 2px $PMDE-primary;
  }

  .sortButton:hover {
    &:not(:focus),
    &:not(:active) {
      svg {
        transition: 0.5s;
        transform: rotateX(180deg);
        transform-style: preserve-3d;
      }
    }
  }

  .usa-button-white:not(:disabled):hover {
    background-color: mix(#fff, $PMDE-primary, 80%);
    color: $PMDE-primary;
  }
}
