.BoxAndWhiskerPlot {
  height: 100%;

  .buttonGroup {
    margin: 0 0.5rem !important;
    top: 0;
    position: sticky;
  }

  .boxAndWhiskerPlotSVG {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    display: flex;
    flex-direction: column;
    background-color: white;

    svg {
      background-color: white;
      order: 4;
    }

    .legendContainerDownload, .chartCaption, .chartTitle, .countCaption {
        display: none;
    }

    .chartCaption, .countCaption {
        font-size: 12px;
        font-style: italic;
    }

    .chartCaption {
      display: none;
      order: 5;
    }

    .countCaption {
      order: 2;
    }

    .chartTitle {
      order: 1;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .legendContainerDownload {
    order: 3;
  }

  .legendBoxWhisker {
    height: 65px;
    width: 200px;
    background: url("../../assets/images/box-and-whisker-legend.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
