.TrendChart {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  display: flex;
  flex-direction: column;


  .chartCaption,
  .countCaption,
  .chartTitle {
    display: none;
  }

  .chartCaption,
  .countCaption {
    font-size: 12px;
    font-style: italic;
  }

  .chartTitle {
    font-size: 16px;
    font-weight: bold;
    order: 1;
  }

  .countCaption{
    order: 2;
  }

  .chartCaption {
    order: 4;
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
    order: 3;
  }

  g:not(.active),
  g:not(.hovered) {
    .path,
    .text,
    .noDataLine {
      cursor: pointer;
      // opacity: 1;
    }
  }

  .active .path,
  .active .text {
    opacity: 1 !important;
  }

  .hovered .path,
  .hovered .text {
    opacity: 1 !important;
  }
}
