@import "../../variables.scss";

.ErrorPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    h1 {
        color: $PMDE-error;
        font-size: 3em;
        margin: .5rem;
    }

    p {
        font-size: 1.5em;
        margin: .5rem;
    }
}