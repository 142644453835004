@import "../../variables.scss";

.DataExplorerPage {
    overflow-x: hidden;
    width: 100%;

    .filterWrapper {
        height: 100%;
        overflow-y: scroll;

        @media screen and (max-width: $ipad-size) {
            height: auto;
            overflow: visible;
        }
    }

    .disableScroll {
        height: 100%;
        overflow-y: hidden;

        @media screen and (max-width: $ipad-size) {
            height: auto;
            overflow: visible;
        }
    }

    .mainContentWrapper {
        height: 100%;
        overflow-y: hidden;

        @media screen and (max-width: $ipad-size) {
            height: auto;
            overflow: visible;
        }
    }
}