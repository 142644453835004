.DownloadDrawer {

    .downloadDrawerLabel,
    .downloadDrawerDescription {
        margin-bottom: 10px;
    }

    .downloadButtonContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 20px 0px;
    }
}