@import "../../variables.scss";

.Table {
    margin-right: .5rem;
    margin-left: .5rem;
    flex: 1;
    overflow-y: auto;
    overflow: hidden;

    .usaTableContainer {
        height: 100%;
    }

    .tableScroll {
        height: 100%;
        overflow: auto;
        max-width: 100%;
        border-collapse: separate;
    }

.stickyCell {
    font-weight: bold
}


    tbody {

        tr {
            td {
                background-color: inherit;
            }
        }

        &:not(:first-of-type) {
            tr:first-of-type {
                td {
                    border-top: 1px solid black;

                }
            }
        }

        tr.summaryRow {

            td{
             font-weight: bold;
            }
        }


        tr:first-of-type {


            td[rowspan] {
                vertical-align: top;
            }
        }

        tr {
            td {
                border-top: none;
                border-bottom: none;
            }
        }
    }

    thead {
        position: sticky;
        top: 0px;
        z-index: 1000;

        .headerRow {
            td {
                background: #fff;
                white-space: normal;
                width: 100px;
                vertical-align: bottom;
            }
        }

        tr {
            td {
                border-top: none;
            }
        }

        th {
            background-color: white;
            .sortableCell{ 
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .boldRow {
        td {
            font-weight: bold;
        }
    }

    .headerRow {
        font-weight: bold;
    }

}